<template>
    <Dialog header="Truabilities Terms Of Service test" v-model:visible="visableTerms" :style="{width: '600px'}" class="tramscondition_modal" :modal="true" @hide="closeTerms()">
        
        <div class="trems_condition_loader"><i v-if="privacyTermsContent === ''" class="pi pi-spin pi-spinner" style="fontSize: 2rem"></i></div>
        <div class="wdg_privacy_terms" v-html="privacyTermsContent">
        </div>
        <template #footer>
            <div class="terms_modal_footer_wrapper">
                <Button class="p-button-primary btn-align-center p-mr-2" label="Yes" icon="pi pi-check" @click="acceptTerms()"  autofocus />
                <Button class="p-button-info  btn-align-center" label="No" icon="pi pi-times" @click="closeTerms()"/>
            </div>
        </template>
    </Dialog>
</template>

<script>
import { termsContent } from '../../../config/appUrls';
import { apiKey, cryptoSecret } from '../../../config/constant';
import { onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

const simpleCrypto = new SimpleCrypto(cryptoSecret);

export default {
    props: ["visibleTerms", "onClickTerm"],
    name: 'TermsModal',
    setup(props){
        let store = useStore();
        let visableTerms = ref(props.visibleTerms);

        const privacyTermsContent = ref ('');
        
        onBeforeMount(()=>{
            if (props.visibleTerms) {
                fetchTermsContent();
            }
        })

        const fetchTermsContent = async() => {
            try{
                const response = await axios.post(termsContent, {
                        payload: simpleCrypto.encrypt({
                            pageType: 'Terms Of Service'
                        })
                    }, {
                        headers: {
                            apiKey: apiKey,
                            'accept-language' : "en",
                            token: store.getters.adminAuthToken,
                            location: 'content'
                        },
                    }
                );
                const rasult = simpleCrypto.decrypt(response.data.data[0]);
                privacyTermsContent.value = rasult;
            } catch(err){
                console.log('error', err);
            }
        };

        const acceptTerms = () => {
            visableTerms.value = false;
            props.onClickTerm('accept');
        }

        const closeTerms = () => {
            visableTerms.value = false;
            props.onClickTerm('reject');
        }

        return{
          visableTerms,
          privacyTermsContent,
          acceptTerms,
          closeTerms
        }
    }
}
</script>

<style lang="scss" scoped>
.tramscondition_modal .p-dialog-footer {
        padding: 1.5rem 1.5rem 1.5rem 1.5rem!important;
}
.p-dialog .p-dialog-footer {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem!important;
}
.trems_condition_loader{
    text-align: center;
    display: flex;
    justify-content: center;
}
.wdg_privacy_terms{
    p{
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 1rem;
        color: #000;
    }
}
.terms_modal_footer_wrapper{
    padding-top: 15px;
}

</style>