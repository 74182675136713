<template>
    <div>
        <form @submit.prevent="updateCard()" :class="['up_customer_card_update', billingDetails.loading ? 'loading pi-spinner' : '']">
        <!-- <form class="loading pi-spinner"> -->
            <div class="up_customer_details">
                <h3>Basic Info</h3>
                <div class="up_customer_details_form">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col">
                            <label for="cdName">Full Name<span class="text-danger">*</span></label>
                            <InputText v-model="billingDetails.name" id="cdName" type="text" />
                            <small class="p-invalid text-danger" v-if="billingDetailsError.nameEmpty">Full Name is required.</small>
                        </div>
                        <div class="p-field p-col">
                            <label for="cdEmail">Email<span class="text-danger">*</span></label>
                            <InputText v-model="billingDetails.email" id="cdEmail" type="text" />
                            <small class="p-invalid text-danger" v-if="billingDetailsError.emailEmpty">Email is required.</small>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col">
                            <label for="cdCountry">Country<span class="text-danger">*</span></label>
                            <Dropdown v-model="billingDetails.country" :options="alllocationData.country" :filter="true" optionLabel="name" optionValue="uuid" placeholder="Select a Country" @change="fetchLocation('onchange')" />
                            <small class="p-invalid text-danger" v-if="billingDetailsError.countryEmpty">Country is required.</small>
                        </div>
                        <div class="p-field p-col">
                            <label for="cdState">County/State<span class="text-danger">*</span></label>
                            <Dropdown v-model="billingDetails.state" :options="alllocationData.state" :filter="true" optionLabel="name" optionValue="uuid" placeholder="Select a County/State" />
                            <small class="p-invalid text-danger" v-if="billingDetailsError.stateEmpty">County/State is required.</small>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col">
                            <label for="cdEmail">Street Address<span class="text-danger">*</span></label>
                            <Textarea v-model="billingDetails.address" rows="3" cols="30" />
                            <small class="p-invalid text-danger" v-if="billingDetailsError.addressEmpty">Street Address is required.</small>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col">
                            <label for="cdCity">City<span class="text-danger">*</span></label>
                            <InputText v-model="billingDetails.city" id="cdCity" type="text" />
                            <small class="p-invalid text-danger" v-if="billingDetailsError.cityEmpty">City is required.</small>
                        </div>
                        <div class="p-field p-col">
                            <label for="cdZipcode">Zipcode<span class="text-danger">*</span></label>
                            <InputText v-model="billingDetails.zipcode" id="cdZipcode" type="text" />
                            <small class="p-invalid text-danger" v-if="billingDetailsError.zipcodeEmpty">Zipcode is required.</small>
                        </div>
                    </div>
                </div>
            </div>
            

            <div class="up_customer_card_info">
                <h3>Credit Card Info</h3>
                <div class="up_customer_card_form">
                    <div class="up_card_type">
                        <ul>
                            <li class="p-mr-2">
                                <img :src="imageUrlFrontEnd + 'images/master_card.png'" alt="">
                            </li>
                            <li class="p-mr-2">
                                <img :src="imageUrlFrontEnd + 'images/amex.png'" alt="">
                            </li>
                            <li class="p-mr-2">
                                <img :src="imageUrlFrontEnd + 'images/visa.jpg'" alt="">
                            </li>
                        </ul>
                    </div>
                    
                    <div class="p-fluid p-formgrid p-grid" >
                        <div class="p-field p-col">
                            <label for="cdCardNumber">Card Number<span class="text-danger">*</span></label>
                            <div class="up_card_dectect">
                              
                                <div id="card-number" class="p-inputmask p-inputtext p-component p-filled"></div>
                             
                                <figure v-if="alllocationData.cardBrand !== ''">
                                    <img :src="imageUrlFrontEnd + alllocationData.cardBrand" alt="">
                                </figure>
                            </div>
                            <small class="p-invalid text-danger" v-if="billingDetailsError.cardNumberEmpty">Card Number is required.</small>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col">
                            <label for="cdHolderName">Cardholder Name<span class="text-danger">*</span></label>
                            <InputText v-model="billingDetails.holderName" id="cdHolderName" type="text" />
                            <small class="p-invalid text-danger" v-if="billingDetailsError.holderNameEmpty">Cardholder Name is required.</small>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col">
                            <label for="cdExpMonth">Expiry Month & Year<span class="text-danger">*</span></label>
                            <div id="card-expiry" class="p-inputtext p-component p-filled"></div>
                            
                            <small class="p-invalid text-danger" v-if="billingDetailsError.expMonthEmpty">Expiry is required.</small>
                        </div>
                       
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-6">
                            <label for="cdCvv">CVV<span class="text-danger">*</span></label>
                            <div class="up_cvv_wrap">
                                <div id="card-cvc" class="p-inputtext p-component p-filled"></div>
                                <img :src="imageUrlFrontEnd + 'dist/img/card-cvv.png'" alt="CVV">
                            </div>
                            <small class="p-invalid text-danger" v-if="billingDetailsError.cvvEmpty">CVV is required.</small>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col">
                            <div class="p-d-flex p-align-center up-terms" v-if="!billingDetails.termsChecked">
                                <Checkbox id="terms" v-model="billingDetails.termsChecked" :binary="true" @click="getUserInfo('click')" />
                                <label for="terms" @click="getUserInfo('click')">I Agree Terms And Conditions</label>
                            </div>
                            <small class="p-invalid text-danger" v-if="billingDetailsError.termsCheckedEmpty">Need to accept Terms And Conditions.</small>
                        </div>
                    </div>
                    <div class="p-fluid up_cd_form_submit p-d-flex">
                        <span class="sup_icon_container">
                            <i class="pi pi-spin pi-spinner" style="fontSize: 1.5rem"></i>
                        </span>
                        <Button type="submit"  icon="pi pi-plus" label="Add Card" class="p-button-primary btn-align-center p-mr-2"/>
                        <Button type="button"  icon="pi pi-refresh" label="Reset" class="p-button-white-shadow  btn-align-center" @click="resetCdForm()" />
                    </div>
                    
                </div>
            </div>

        </form>
        <!-- Billing Details Modal -->
        <BillingDetailsModal v-if="billingDetails.billingDetailsModal" :visible="billingDetails.billingDetailsModal" />
        <!-- Billing Details Modal -->
        <TermsModal v-if="termsModalShow" :visibleTerms="termsModalShow" :onClickTerm="getUserInfo" />
        <Toast />
    </div>
</template>

<script>
import { allLocation, userPortalBilling, userPortalCardList, userPortalBillingTerms, userPortalCardBrand } from '../../../config/appUrls';
import { apiKey, imageUrlFrontEnd, stripeKey } from '../../../config/constant';
import { cryptoSecret } from '../../../config/constant';
import TermsModal from '../popups/AcceptTerms';
import { reactive, onBeforeMount, computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import { useToast } from "primevue/usetoast";
import BillingDetailsModal from '../popups/BillingDetails.vue';
import userSys from 'ua-parser-js';
import router from "../../../router";
import SimpleCrypto from "simple-crypto-js";
import { loadStripe } from '@stripe/stripe-js';

const simpleCrypto = new SimpleCrypto(cryptoSecret);

export default {
    props: ["onClicked"],
    name: "BillingScreen",
    components: {
        TermsModal,
        BillingDetailsModal
    },
    setup(props){
        let store = useStore();

        //Toast Message
        const toast = useToast();

        const stripe = ref(null);
        const elements = ref(null);
        const cardNumber = ref(null);
        const cardExpiry = ref(null);
        const cardCvc = ref(null);


        onMounted(async () => {
            const stripePromise = loadStripe(stripeKey);
            
            const stripeInstance = await stripePromise;
            const elementsInstance = stripeInstance.elements();

            stripe.value = stripeInstance;
            elements.value = elementsInstance;

            cardNumber.value = elementsInstance.create('cardNumber', { 
                placeholder : "", 
                style: {
                    base: {
                        fontSize: '16px'
                    },
                }
            });
            cardNumber.value.mount('#card-number');
            cardNumber.value.addEventListener('change', function(event) {

                switch (event.brand) {
                        case 'mastercard':
                            alllocationData.cardBrand = 'images/master_card.png';
                            break;
                        case 'visa':
                            alllocationData.cardBrand = 'images/visa.jpg';
                            break;
                        case 'amex':
                            alllocationData.cardBrand = 'images/amex.png';
                            break;
                        default:
                            alllocationData.cardBrand = '';
                            break;
                    }

                    
                if (event.empty === true) {
                    billingDetailsError.cardNumberEmptyValid = true;
                    //billingDetailsError.cardNumberEmpty = true;
                }
                else
                {
                    billingDetailsError.cardNumberEmptyValid = false;
                    billingDetailsError.cardNumberEmpty = false;
                }
            });

            
            cardExpiry.value = elementsInstance.create('cardExpiry', {
                style: {
                    base: {
                        fontSize: '16px'
                    },
                }
            });
            cardExpiry.value.mount('#card-expiry');
            cardExpiry.value.addEventListener('change', function(event) {


                if (event.empty === true) {
                    billingDetailsError.expMonthEmptyValid = true;
                    //billingDetailsError.expMonthEmpty = true;
                }
                else
                {
                    billingDetailsError.expMonthEmptyValid = false;
                    billingDetailsError.expMonthEmpty = false;
                }
            });

            cardCvc.value = elementsInstance.create('cardCvc', {
                placeholder: '', 
                style: {
                    base: {
                        fontSize: '16px'
                    },
                }
            });
            cardCvc.value.mount('#card-cvc');
            cardCvc.value.addEventListener('change', function(event) {
                if (event.empty === true) {
                    billingDetailsError.cvvEmptyValid = true;
                    //billingDetailsError.cvvEmpty = true;
                }
                else
                {
                    billingDetailsError.cvvEmptyValid = false;
                    billingDetailsError.cvvEmpty = false;
                }
            });
        });

        //Location state
        const alllocationData = reactive({
            country: null,
            state: null,
            status: null,
            termsAlreadyChecked: false,
            cardBrand: ''
        });

        const termsModalShow = ref(false);

        const billingDetails = reactive({
            name: '',
            email: '',
            country: '',
            state: '',
            address: '',
            city: '',
            zipcode: '',
            cardNumber: '',
            holderName: '',
            expMonth: '',
            expYear: '',
            cvv: '',
            termsChecked: '',
            status: null,
            message: '',
            loading: false,
            billingDetailsModal : false
        });

        const billingDetailsError = reactive({
            nameEmpty: false,
            emailEmpty: false,
            countryEmpty: false,
            stateEmpty: false,
            addressEmpty: false,
            cityEmpty: false,
            zipcodeEmpty: false,
            cardNumberEmpty: false,
            holderNameEmpty: false,
            expMonthEmpty: false,
            expYearEmpty: false,
            cvvEmpty: false,
            termsCheckedEmpty: false,
            cardNumberEmptyValid: true,
            expMonthEmptyValid: true,
            cvvEmptyValid: true,
            
        });

        const userCardStatus = computed(() => store.getters.customerDetails);

        onBeforeMount(()=>{
            fetchLocation('intialLoad');
            fetchUser();
        });

        function reloadPage() {
            window.location.reload();
        }

        const fetchLocation = async(load) => {
            const response = await axios.get( allLocation, {
                params: {
                    uuid: billingDetails.country
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });
            
            if (response.data.status === 200) {
                //If condition for country and state segrigation
                if (load === 'intialLoad') {
                    alllocationData.country = response.data.data;
                } else {
                    alllocationData.state = response.data.data;
                }
            }
        }

        const fetchUser = async() =>{
            const response = await axios.get(userPortalBilling, {
                params: {
                    uuid: userCardStatus.value.uuid
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });
            
            if (response.data.status === 200) {
                billingDetails.country = response.data.data.countryUuid;
                await fetchLocation('state');
                billingDetails.name = response.data.data.name;
                billingDetails.email = response.data.data.email;
                billingDetails.city = response.data.data.city;
                billingDetails.address = response.data.data.addressOne;
                billingDetails.zipcode = response.data.data.zipcode;
                billingDetails.termsChecked = response.data.data.isCheckedTerms;
                alllocationData.termsAlreadyChecked = response.data.data.isCheckedTerms;
                billingDetails.state = response.data.data.stateUuid;
            }
        }

        const updateCard = async() => {
             
            let isValidate = cardFormValidation();
            try {
                 if (isValidate) {
                    
                    const { token, error } = await stripe.value.createToken(cardNumber.value,cardExpiry.value,cardCvc.value);
                   
                    if (error) {
                   
                    // Handle error (e.g., display error message to the user)
                    } else {
                        // Send token to your server for processing
                        billingDetails.loading = !billingDetails.loading;
                        const response = await axios.post( userPortalCardList, {
                            payload: simpleCrypto.encrypt({
                                fullName: billingDetails.name,
                                email: billingDetails.email,
                                country: billingDetails.country,
                                state: billingDetails.state,
                                addressOne: billingDetails.address,
                                city: billingDetails.city,
                                zipcode: billingDetails.zipcode,
                                token: token.id,
                                card_id: token.card.id
                            })
                           
                        },{
                            headers: {
                                apiKey: apiKey,
                                token: store.getters.adminAuthToken
                            }
                        });
                        if (response.status === 200) {
                            if (Object.keys(props).length > 0) {
                                props.onClicked();
                            }
                            billingDetails.loading = !billingDetails.loading;
                            
                            let UserData = JSON.parse(localStorage.getItem("customer-details"));
                            UserData.userCard = true;
                            localStorage.setItem("customer-details", JSON.stringify(UserData));
                            store.dispatch('customerDetailsAC',{
                                value: UserData
                            });
                            fetchUser();
                            resetCdForm();
                            billingDetails.billingDetailsModal = false
                            if (window.location.pathname === '/customer/dashboard') {
                                router.push({name: "CustomerBillingDetails"});
                            } else {
                                toast.add({severity:'success', summary:'Card Added!', detail:'Card added to profile.', life: 2000});
                            }
                            // router.push({name: "CustomerBillingDetails"});
                            // router.push({name: 'CustomerEditWebsite'});
                        }
                    // You can now send this token to your server to create a charge
                    }
                    
                }
            } catch (error) {
                
                if(error.response.status === 400){
                    billingDetails.loading = !billingDetails.loading;
                    toast.add({severity:'error', summary:'Card Error', detail:error.response.data.message, life: 3000});
                    resetCdForm();

                }
            }
            
        }

        

        const cardFormValidation = () => {

           
            if(billingDetailsError.cardNumberEmptyValid == false){
                billingDetailsError.cardNumberEmpty = false
            } else {
                billingDetailsError.cardNumberEmpty = true
            }

            if(billingDetailsError.expMonthEmptyValid == false){
                billingDetailsError.expMonthEmpty = false
            } else {
                billingDetailsError.expMonthEmpty = true
            }

            if(billingDetailsError.cvvEmptyValid == false){
                billingDetailsError.cvvEmpty = false
            } else {
                billingDetailsError.cvvEmpty = true
            }
            

            if (billingDetails.name === '') {
                billingDetailsError.nameEmpty = true;
            } else {
                billingDetailsError.nameEmpty = false;
            }

            if (billingDetails.email === '') {
                billingDetailsError.emailEmpty = true;
            } else {
                billingDetailsError.emailEmpty = false;
            }

            if (billingDetails.country === '') {
                billingDetailsError.countryEmpty = true;
            } else {
                billingDetailsError.countryEmpty = false;
            }

            if (billingDetails.state === '') {
                billingDetailsError.stateEmpty = true;
            } else {
                billingDetailsError.stateEmpty = false;
            }

            if (billingDetails.address === '') {
                billingDetailsError.addressEmpty = true;
            } else {
                billingDetailsError.addressEmpty = false;
            }

            if (billingDetails.city === '') {
                billingDetailsError.cityEmpty = true;
            } else {
                billingDetailsError.cityEmpty = false;
            }

            if (billingDetails.zipcode === '') {
                billingDetailsError.zipcodeEmpty = true;
            } else {
                billingDetailsError.zipcodeEmpty = false;
            }

            if (billingDetails.holderName === '') {
                billingDetailsError.holderNameEmpty = true;
            } else {
                billingDetailsError.holderNameEmpty = false;
            }

            if (billingDetails.termsChecked === '' || billingDetails.termsChecked === false) {
                billingDetailsError.termsCheckedEmpty = true;
            } else {
                billingDetailsError.termsCheckedEmpty = false;
            }

            if (!billingDetailsError.nameEmpty && !billingDetailsError.emailEmpty && !billingDetailsError.countryEmpty && !billingDetailsError.stateEmpty && !billingDetailsError.addressEmpty && !billingDetailsError.cityEmpty && !billingDetailsError.zipcodeEmpty && !billingDetailsError.cardNumberEmpty && !billingDetailsError.holderNameEmpty && !billingDetailsError.expMonthEmpty && !billingDetailsError.expYearEmpty && !billingDetailsError.cvvEmpty && !billingDetailsError.termsCheckedEmpty ) {
                return true;
            } else {
                return false;
            }
        }

        const cardValidate = async() => {
            let num = parseInt(billingDetails.cardNumber.replace(/\s/g, ''));
            if(`${num}`.length === 2 ){
                const response = await axios.post(userPortalCardBrand, {
                    cardNumber: parseInt(billingDetails.cardNumber.replace(/\s/g, '')).toString()
                }, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    },
                });
                alllocationData.cardBrand = response.data.data;
                switch (response.data.data) {
                    case 'mastercard':
                        alllocationData.cardBrand = 'images/master_card.png';
                        break;
                    case 'visa':
                        alllocationData.cardBrand = 'images/visa.jpg';
                        break;
                    case 'american-express':
                        alllocationData.cardBrand = 'images/amex.png';
                        break;
                    default:
                        alllocationData.cardBrand = '';
                        break;
                }
            }
        }

        const getUserInfo = async(load) => {
            if (load === 'click') {
                termsModalShow.value = true;
            } 
            if(load === 'accept') {
                termsModalShow.value = false;
                billingDetails.termsChecked = true;
                const deviceInfo = {};

                // Device info fetch
                const parser = new userSys();
                var result = parser.getResult();
                
                // Client Ip fetch
                const clientIp = await axios.get('https://json.geoiplookup.io/');

                // New deive info object Create start
                deviceInfo["browserName"] = result.browser.name;
                deviceInfo["browserVersion"] = result.browser.version;
                deviceInfo["osName"] = result.os.name;
                deviceInfo["osVersion"] = result.os.version;
                deviceInfo["ipAddress"] = clientIp.data.ip;
                deviceInfo["ipType"] = clientIp.data.connection_type;
                deviceInfo["continentCode"] = clientIp.data.continent_code;
                deviceInfo["continentName"] = clientIp.data.continent_name;
                deviceInfo["countryCode"] = clientIp.data.country_code;
                deviceInfo["countryName"] = clientIp.data.country_name;
                deviceInfo["regionName"] = clientIp.data.region;

                await axios.post(userPortalBillingTerms, {
                    browserName: deviceInfo.browserName,
                    browserVersion: deviceInfo.browserVersion,
                    osName: deviceInfo.osName,
                    osVersion: deviceInfo.osVersion,
                    ipAddress: deviceInfo.ipAddress,
                    ipType: deviceInfo.ipType,
                    continentCode: deviceInfo.continentCode,
                    continentName: deviceInfo.continentName,
                    countryCode: deviceInfo.countryCode,
                    countryName: deviceInfo.countryName,
                    regionCode: deviceInfo.regionCode,
                    regionName: deviceInfo.regionName,
                    city: deviceInfo.city,
                    zipcode: deviceInfo.zipcode,
                    latitude: deviceInfo.latitude,
                    longitude: deviceInfo.longitude,
                }, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    },
                });
            }
            if(load === 'reject') {
                termsModalShow.value = false;
                billingDetails.termsChecked = false;
            }
        }

        const resetCdForm = () =>{
            billingDetails.cardNumber= '';
            billingDetails.holderName= '';
            billingDetails.expMonth= '';
            billingDetails.expYear= '';
            billingDetails.cvv= '';
            billingDetails.status= null;
            billingDetails.message= '';
            alllocationData.cardBrand= '';
            cardNumber.value.clear();
            cardExpiry.value.clear();
            cardCvc.value.clear();
        }

        return{
            billingDetails,
            alllocationData,
            billingDetailsError,
            imageUrlFrontEnd,
            termsModalShow,
            elements,
            stripe,
            cardNumber,
            cardExpiry,
            cardCvc,
            updateCard,
            fetchLocation,
            cardValidate,
            getUserInfo,
            resetCdForm,
            reloadPage
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/style/component/userportal/views/BillingScreen';

</style>