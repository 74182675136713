<template>
    <Dialog header="Billing Details" v-model:visible="visableBillingModal" :style="{width: '1024px'}" :modal="true" >
        <Billingscreen />
    </Dialog>
</template>

<script>
import { ref } from 'vue';
import Billingscreen from '../views/BillingScreen';

export default {
    props: ["visible"],
    name: 'BillingDetailsModal',
    components: {
        Billingscreen,
    },
    setup(props){
        let visableBillingModal = ref(props.visible);

        return{
          visableBillingModal  
        }
    }
}
</script>